import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Modal, Box, Typography, IconButton, Divider, Button, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import SelectComponent from '../components/SelectComponent';
import { getSessions, useUpdateSessionsStatus } from '../service/sessions';
import { useUser } from '../contexts/UserContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { showToastFailure, showToastSuccess } from '../service/toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, startOfDay, endOfDay } from 'date-fns';

const SessionsTable = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const todayDate = new Date()
    const [startDate, setStartDate] = useState(startOfDay(todayDate));
    const [endDate, setEndDate] = useState(endOfDay(todayDate));

    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const { user } = useUser();

    const { updateSessionsStatus } = useUpdateSessionsStatus()

    const gameStatuses = [
        { value: 'ongoing', label: 'On Going' },
        { value: 'completed', label: 'Completed' },
    ];

    const fetchData = async () => {

        // Format the dates to ISO 8601 strings
        const startOfTodayISOString = new Date(startDate).toISOString();    
        const endOfTodayISOString =  new Date(endDate).toISOString();

        const data = await getSessions(startOfTodayISOString, endOfTodayISOString);

        const formattedData = data?.map(item => ({
            id: item.session_id,
            orderId: item.order_id,
            feedback: item.feedback,
            gameStatus: item.game_status,
            session_feedback_ambiance: item?.feedback_ambiance,
            session_feedback_games: item?.feedback_games,
            session_feedback_overall: item.feedback_overall,
            createdAt: item.created_at && format(new Date(item.created_at), 'dd/MM/yyyy HH:mm a'),
            updatedAt: item.updated_at && format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm a'),
            orderUserName: `${item.order_details.user.first_name} ${item.order_details.user.last_name}`,
            orderUserEmail: item.order_details.user.email,
            orderUserPhone: item.order_details.user.phone,
            passTitle: item.order_details.pass_details.title,
            passAmount: item.order_details.pass_details.amount,
            passMaxHours: item.order_details.pass_details.max_hours_allowed,
            orderAmount: item.order_details.amount,
            paymentId: item.order_details.payment_id,
            no_of_ticket_purchased: item.order_details.no_of_ticket_purchased,
            orderGstAmount: item.order_details.gst_amount,
            paymentStatus: item.order_details.payment_status ? 'Success' : 'Failed',
            updatedByUserName: item.updated_by_user.first_name && `${item.updated_by_user.first_name} ${item.updated_by_user.last_name}`,
            updatedByUserEmail: item.updated_by_user.email,
            order_metadata: item.order_metadata ?? [],
            payment_mode: item.order_details.payment_mode,
            order_confirmed: item.order_details.order_confirmed ? 'Yes': 'No'
        }));

        setRows(formattedData);
        setLoading(false);
    };


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStartDateChange = (date) => {
        if (date && endDate && date > endDate) {
            showToastFailure('Start date cannot be after end date.')
            setStartDate(null);
        } else {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
            showToastFailure('End date cannot be before start date.')
            setEndDate(null);
        } else {
            setEndDate(date);
        }
    };

    const handleOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    const updateGameStatus = async (status, id) => {
        const updatedOrder = await updateSessionsStatus(id, status)
        if (updatedOrder?.length) {
            const updatedRow = updatedOrder[0];
            setRows(prevRows =>
                prevRows.map(row =>
                    row.id === updatedRow.id
                        ? {
                            ...row, gameStatus: updatedRow.game_status,
                            updatedAt: format(new Date(), 'dd/MM/yyyy HH:mm a'),
                            updatedByUserName: user?.fullName || '',
                            updatedByUserEmail: user?.email || '',
                        }
                        : row
                )
            );
        }
    }

    const copyToClipboard = (row) => {
        if (row) {
            const textToCopy = `
            Order ID: ${row.orderId}
            Ordered By: ${row.orderUserName}
            Email: ${row.orderUserEmail}
            Phone: ${row.orderUserPhone}
            Feedback: ${row.feedback || ''}
            Ambiance:  ${row.session_feedback_ambiance || ''}
            Games:  ${row.session_feedback_games || ''}
            Overall :  ${row.session_feedback_overall || ''}
            Game Status: ${row.gameStatus}
            Created At: ${row.createdAt}
            Updated At: ${row.updatedAt || 'N/A'}
            Updated By: ${row.updatedByUserName || 'N/A'}

            Tickets Sold: ${row.no_of_ticket_purchased}
            Order Amount: ${row.orderAmount}
            Order GST Amount: ${row.orderGstAmount}
            Payment Id: ${row.paymentId}

            Pass Title: ${row.passTitle}
            Pass Amount: ${row.passAmount}
            Max Hours Allowed: ${row.passMaxHours}
            `;

            navigator.clipboard.writeText(textToCopy).then(() => {
                showToastSuccess('Data Copied successfully!')
            });
        }
    };



    const columns = [
        { field: 'orderUserName', headerName: 'Ordered By', flex: 1 },
        { field: 'orderUserPhone', headerName: 'Phone', flex: 0.6 },
        { field: 'feedback', headerName: 'Feedback', flex: 1, 

            renderCell: (params) => (
                <Tooltip title={Array.isArray(params.value) ? params.value.join(', ') : params.value || ''} arrow>
                <span>{Array.isArray(params.value) ? params.value.join(', ') : params.value ?? ''}</span>
              </Tooltip>
              ),
         },
        { field: 'session_feedback_ambiance', headerName: 'Ambiance', flex: 0.5 },
        { field: 'session_feedback_games', headerName: 'Games', flex: 0.5 },
        { field: 'session_feedback_overall', headerName: 'Overall', flex: 0.5 },
        {
            field: 'gameStatus', headerName: 'Game Status', flex: 1,
            renderCell: (params) => (
                <Box sx={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    p: 1, // Padding for spacing
                }}>

                    <SelectComponent
                        value={params.value}
                        options={gameStatuses}
                        onChange={(status) => updateGameStatus(status, params.row.id)}
                        placeholder="Select Status"
                    />
                </Box>

            ),
        },
        { field: 'createdAt', headerName: 'Created At', flex: 1 },
        { field: 'updatedAt', headerName: 'Updated At', flex: 1 },
        {
            field: 'updatedByUserName',
            headerName: 'Updated By',
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Actions',
            renderCell: (params) => (
                <IconButton onClick={() => handleOpen(params.row)}>
                    <VisibilityIcon />
                </IconButton>

            ),
            flex: 0.6
        },
        {
            field: 'copy',
            headerName: 'Copy Data',
            renderCell: (params) => (
                <IconButton onClick={() => copyToClipboard(params.row)}>
                    <ContentCopyIcon />
                </IconButton>

            ),
            flex: 0.6
        }
    ];

    return (

        <div style={{ padding: '10px 0' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <div style={styles.datepickerWrapper}>
                    <DatePicker
                        showIcon
                        closeOnScroll={true}
                        isClearable
                        toggleCalendarOnIconClick
                        selected={startDate}
                        value={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Start Date"
                        style={{ zIndex: 1000 }}
                    />
                </div>
                <div style={styles.datepickerWrapper}>
                    <DatePicker
                        showIcon
                        closeOnScroll={true}
                        isClearable
                        toggleCalendarOnIconClick
                        selected={endDate}
                        value={endDate}
                        onChange={handleEndDateChange}
                        maxDate={new Date()}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="End Date"
                        style={{ zIndex: 1000 }}
                    />
                </div>
                <Button
                    variant="contained"
                    style={{ backgroundColor: '#756394', color: '#fff' }}
                    onClick={() => {
                        if (startDate && endDate) {
                            fetchData()
                        }
                    }}
                >
                    Filter
                </Button>
            </div>

            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    loading={loading}
                />

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        height: 800,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography id="modal-title" variant="h6" component="h2">
                                Sessions Details
                            </Typography>
                            <IconButton onClick={handleClose} color="primary">
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        {selectedRow && (
                            <Box>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Order User Name:</strong> {selectedRow.orderUserName}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Order User Email:</strong> {selectedRow.orderUserEmail}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Order User Phone:</strong> {selectedRow.orderUserPhone}</Typography>
                                <Divider sx={{ my: 2 }} />
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Game Status:</strong> {selectedRow.gameStatus}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Feedback:</strong> {selectedRow.feedback}</Typography>
                        

                                <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                    <Typography sx={{ mr: 2 }} variant="subtitle1">
                                        <strong>Games:</strong> {selectedRow.session_feedback_games}
                                    </Typography>
                                    <Typography sx={{ mr: 2 }} variant="subtitle1">
                                        <strong>OverAll:</strong> {selectedRow.session_feedback_overall}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Ambiance:</strong> {selectedRow.session_feedback_ambiance}
                                    </Typography>
                                </Box>
                                <Divider sx={{ my: 2 }} />

                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Tickets Sold:</strong> {selectedRow?.no_of_ticket_purchased}</Typography>

                                {selectedRow?.order_metadata?.length > 0 && <Typography sx={{ mr: 2 }} variant="subtitle1">
                                    <strong>Extra Info </strong>

                                    {selectedRow?.order_metadata?.map((metaData => {
                                        return (
                                            <>
                                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Full Name:</strong> {metaData?.fullName}</Typography>
                                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Phone:</strong> {metaData?.phone}</Typography>
                                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Email:</strong> {metaData.email}</Typography>
                                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>DOB:</strong> {format(new Date(metaData.dob), 'dd/MM/yyyy')}</Typography>
                                                
                                                <Divider sx={{ my: 2 }} />
                                            </>
                                        )
                                    }))}
                                    <Divider sx={{ my: 2 }} />

                                </Typography>}

                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Order Confirmed:</strong> {selectedRow.order_confirmed}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Payment Mode:</strong> {selectedRow.payment_mode}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Order Amount:</strong> {selectedRow.orderAmount}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Order GST Amount:</strong> {selectedRow.orderGstAmount}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Payment Id:</strong> {selectedRow.paymentId}</Typography>
                                <Divider sx={{ my: 2 }} />
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Pass Title:</strong> {selectedRow.passTitle}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Pass Amount:</strong> {selectedRow.passAmount}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Max Hours Allowed:</strong> {selectedRow.passMaxHours}</Typography>
                                <Divider sx={{ my: 2 }} />
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Created At:</strong> {selectedRow.createdAt}</Typography>
                                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Updated By User:</strong> {selectedRow.updatedByUserName}</Typography>
                            </Box>
                        )}
                    </Box>
                </Modal>
            </div>

        </div>
    );
};

export default SessionsTable;


const styles = {
    datepickerWrapper: {
        display: 'inline-block',
        border: '2px solid #756394', /* Border color */
        borderRadius: '8px', /* Rounded corners */
        padding: '8px', /* Padding around the DatePicker */
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', /* Shadow for depth */
        backgroundColor: '#fff', /* Background color */
        position: 'relative', /* Ensure z-index works */
    },
    reactDatepickerTriangle: {
        display: 'none', /* Hide the triangle if you don't want it */
    }
};
