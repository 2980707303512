import { useLoading } from '../contexts/LoadingContext'; 
import { supabase } from '../client';
import { showToastSuccess } from './toast';

export const useDashboardData = () => {
  const { showLoader, hideLoader } = useLoading();

  const getDashboardData = async () => {
    showLoader();
    try {
      const { data, error } = await supabase.rpc('get_dashboard_data');
      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      throw error;
    } finally {
      hideLoader();
    }
  };


  const deleteExpiredEventImages = async () => {
    const today = new Date().toISOString();

    // Fetch expired events with image URLs
    const { data: events, error: fetchError } = await supabase
      .from('events') // Replace with your actual table name
      .select('id, image_url')
      .lt('event_end_time', today)
      .not('image_url', 'is', null);

    if (fetchError) {
      console.error('Error fetching expired events:', fetchError);
      return;
    }

    if(!events.length){
      return showToastSuccess('Nothing to delete, everything is upto date!!!')
    }

    // Filter out events that have an image_url and prepend the folder path
    const imagesToDelete = events
      .filter(event => event.image_url)
      .map(event => `images/events/${event.image_url}`); // Include the folder path

    if (imagesToDelete.length > 0) {
      // Delete images from storage in a single call
      const { error: storageError } = await supabase
        .storage
        .from('images') // Replace with your storage bucket
        .remove(imagesToDelete);

      if (storageError) {
        console.error('Error deleting images:', storageError);
      } else {
        // Update the database to set image_url to null and in_active to true for the expired events
        const { error: updateError } = await supabase
          .from('events') // Replace with your actual table name
          .update({ image_url: null, is_active: false })
          .in('id', events.map(event => event.id)); // Update all expired events in one call

        if (updateError) {
          console.error('Error updating events:', updateError);
        }else{
          showToastSuccess('Images Deleted Successfully')
        }
      }
    }
  };


  return { getDashboardData, deleteExpiredEventImages };
};
