import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import ViewQR from '../components/viewQR'
import { styled } from '@mui/material/styles';

// Styled Card
const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '12px',
    boxShadow: theme.shadows[4],
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  }));

const Qr = () => {
  return (
    <Grid item xs={12}>
      <StyledCard>
        <CardContent sx={{ display: 'flex', flexDirection: 'column'}}>
          <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
            QR Codes
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column',marginTop: 5, justifyContent: 'center' }}>
            <ViewQR />
          </Box>
        </CardContent>
      </StyledCard>
    </Grid>
  );
};

export default Qr;
