import React from 'react'
import SideNavDrawer from '../components/SideNavDrawer'
import EventDetailsTable from '../tables/EventsDetailsTable'
import EventDetailForm from '../forms/EventDetailsForm'

function Events() {
  return (
    <>
      <SideNavDrawer component={EventDetailForm} />
      <div style={{ marginTop: '20px' }}>
        <EventDetailsTable />
      </div>
    </>
  )
}

export default Events