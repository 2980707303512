import React from 'react';
import { UserProvider } from './UserContext';
import { PassProvider } from './PassContext';
import { LoadingProvider } from './LoadingContext';
import { EventProvider } from './EventContext';

// Create a combined provider component
const AppProviders = ({ children }) => {
  return (
    <LoadingProvider>
    <UserProvider>
      <PassProvider>
        <EventProvider>
        {children}
        </EventProvider>
        </PassProvider>
    </UserProvider>
    </LoadingProvider>
  );
};

export default AppProviders;
