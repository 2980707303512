import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEventContext } from '../contexts/EventContext';

// Define the schema using yup
const schema = yup.object({
    name: yup.string().required('Event name is required'),
    description: yup.string().required('Description is required'),
    price: yup.number()
        .typeError('Price must be a number')
        .required('Price is required')
        .positive('Price must be positive'),
    no_of_tickets: yup.number()
        .typeError('Maximum Ticket Allowed must be a number')
        .required('Maximum Ticket Allowed is required')
        .positive('Value must be positive'),
    event_start_date_time: yup
        .date()
        .required('Start Date Time is required'),
    event_end_time: yup
        .date()
        .required('End Date Time is required')
        .min(
            yup.ref('event_start_date_time'),
            'End Date Time cannot be before Start Date Time'
        ),
        image: yup.mixed()
        .test('fileSize', 'File size should be less than 300KB', (file) => {
          if (!file || file.length === 0) {
            return true; // Don't apply validation if no file is selected
          }
          return file[0].size <= 300000; // Validate file size
        })
        .nullable(),
});

const EventDetailForm = ({ onClose }) => {

    const { createNewEvent } = useEventContext();
    const [startDateTime, setStartDateTime] = useState(null);
    const [imageError, setImageError] = useState('');
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('price', data.price);
        formData.append('no_of_tickets', data.no_of_tickets);
        formData.append('event_start_date_time', data.event_start_date_time);
        formData.append('event_end_time', data.event_end_time);

       if (data.image && data.image.length) {
            formData.append('image', data.image[0]);
        }

        const eventDetails = {
            name: formData.get('name'),
            description: formData.get('description'),
            price: formData.get('price'),
            no_of_tickets: formData.get('no_of_tickets'),
            event_start_date_time: new Date(formData.get('event_start_date_time')).toISOString(),
            event_end_time: new Date(formData.get('event_end_time')).toISOString(),
            image: formData.get('image') 
        };

        const response = await createNewEvent(eventDetails);
        if (response) {
            if (onClose) onClose();
            reset();
        }
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size > 300000) {
            setImageError('File size exceeds 300 KB. Please try reducing the size.');
        } else {
            setImageError('');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 16 }}>
            <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Name"
                        fullWidth
                        margin="normal"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                )}
            />
            <Controller
                name="description"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Description"
                        fullWidth
                        multiline
                        rows={5}
                        margin="normal"
                        error={!!errors.description}
                        helperText={errors.description?.message}
                    />
                )}
            />
            <Controller
                name="price"
                defaultValue=""
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Price"
                        fullWidth
                        margin="normal"
                        type="number"
                        error={!!errors.price}
                        helperText={errors.price?.message}
                    />
                )}
            />

            <Controller
                defaultValue=""
                name="no_of_tickets"
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Available Tickets"
                        fullWidth
                        margin="normal"
                        type="number"
                        error={!!errors.no_of_tickets}
                        helperText={errors.no_of_tickets?.message}
                    />
                )}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        name="event_start_date_time"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <DateTimePicker
                                {...field}
                                minDateTime={new Date(new Date().getTime() + 30 * 60 * 1000)}
                                inputFormat="dd/MM/yyyy"
                                label="Event Start Date & Time"
                                DialogProps={{
                                    disableEscapeKeyDown: true, // Prevent closing with escape key
                                    onBackdropClick: (event) => event.stopPropagation(), // Prevent closing on backdrop click
                                  }}
                                onChange={(date) => {
                                    field.onChange(date); // Pass the change to the form controller
                                    setStartDateTime(date); // Update the state for the end time validation
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        margin="normal"
                                        error={!!errors.event_start_date_time}
                                        helperText={errors.event_start_date_time?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        name="event_end_time"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <DateTimePicker
                                {...field}
                                minDateTime={startDateTime} // Ensure end date is not before start date
                                inputFormat="dd/MM/yyyy"
                                label="Event End Date & Time"
                                DialogProps={{
                                    disableEscapeKeyDown: true, // Prevent closing with escape key
                                    onBackdropClick: (event) => event.stopPropagation(), // Prevent closing on backdrop click
                                  }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        margin="normal"
                                        error={!!errors.event_end_time}
                                        helperText={errors.event_end_time?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>

            <div style={{ marginTop: 16 }}>
                <Controller
                    name="image"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    field.onChange(e.target.files);
                                    handleImageChange(e);
                                }}
                                style={{ display: 'block', marginBottom: 8 }}
                            />
                            {imageError && (
                                <p style={{ color: 'red', fontSize: 12 }}>{imageError}</p>
                            )}
                            <p>
                                Need help reducing the file size? Try this
                                <a
                                    href="https://www.simpleimageresizer.com/resize-image-to-300-kb"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 underline ml-1"
                                >
                                    online image converter
                                </a>
                            </p>
                        </>
                    )}
                />
               
            </div>

            <Button
                type="submit"
                variant="contained"
                sx={{
                    backgroundColor: '#756394',
                    color: '#FFFFFF',
                    marginTop: '10px'
                }}
                fullWidth
            >
                Submit
            </Button>
        </form>
    );
};

export default EventDetailForm;
