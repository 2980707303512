import React, { useCallback, useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { qetQRCodes, toggleQRCodeStatus } from '../service/qrService';
import Switch from '@mui/material/Switch';
import { IconButton, TextField } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { showToastSuccess } from '../service/toast';
import { getQRCodeDescription } from '../utils/QrCodeTypes';

const ViewQR = () => {
  const [qrCodes, setQRCodes] = useState([]);

  const fetchQRCodes = useCallback(async () => {
    const data = await qetQRCodes();
    const qrCodeWithURL = data?.map(qrData => {
      return {
        ...qrData,
        qr: `${process.env.REACT_APP_URL}?q=${qrData.qr}`,
        hasChangeStatusOption: qrData.type !== 'new_registration'
      };
    });
    setQRCodes(qrCodeWithURL);
  }, [])


  useEffect(() => {
    fetchQRCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleToggle = async (qrData) => {
    const updatedQR = await toggleQRCodeStatus(qrData?.id);
    if (updatedQR) {
      const formattedQR = {
        ...qrData,
        is_active: !qrData.is_active
      }

      setQRCodes((prevQRs) =>
        prevQRs.map((qr) => (qr.id === qrData.id ? formattedQR : qr))
      );
    }
  };


  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    showToastSuccess('QR Copied')
  };

  return (

    <div className="flex justify-between">
      {qrCodes?.length > 0 && qrCodes?.map((qrCode, index) => (
          <div key={index} className="flex flex-col items-center space-y-4 bg-white p-12 rounded-lg shadow-md w-1/2">

          <label className="text-md underline font-bold">
            {getQRCodeDescription(qrCode.type)}
          </label>

          <QRCodeSVG value={qrCode.qr} />

          <div className="flex items-center space-x-2">
            <TextField
              label="QR URL"
              value={qrCode.qr}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
              size="small"
            />
            <IconButton
              onClick={() => handleCopy(qrCode.qr)}
              aria-label="copy to clipboard"
            >
              <ContentCopy />
            </IconButton>
          </div>

          {qrCode?.hasChangeStatusOption && <div className="flex items-center space-x-2">
            <Switch
              checked={qrCode.is_active}
              onChange={() => handleToggle(qrCode)}
              name="qrStatusToggle"
              inputProps={{ 'aria-label': 'QR Code Active Status' }}
              sx={{
                '& .MuiSwitch-switchBase': {
                  color: '#9c27b0', // Purple color for the switch thumb
                  '&.Mui-checked': {
                    color: '#9c27b0', // Purple color when checked
                  },
                  '&.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#9c27b0', // Purple color for the track when checked
                  },
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#ccc', // Default color for the track
                },
              }}
            />

            <label className="text-sm font-medium">
              {qrCode.is_active ? 'Active' : 'Inactive'}
            </label>
          </div>}
        </div>
      ))}
    </div>


  );
};

export default ViewQR;
