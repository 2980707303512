import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, TextField, IconButton, Tooltip } from '@mui/material';
import SwitchWithLabels from '../components/SwitchWithLables';
import { useEventContext } from '../contexts/EventContext';
import { format } from 'date-fns';
import { showToastFailure, showToastSuccess } from '../service/toast';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EventDetailsModal from '../components/EventDetailsModal';
import { getOrderEventDetails } from '../service/eventService';


const EventDetailsTable = () => {

  const [rows, setRows] = useState([]);
  const { events, updateEventData } = useEventContext();
  const [editingRowId, setEditingRowId] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);


  useEffect(() => {
    if (events?.length > 0) {
      const formattedData = events?.map(item => ({
        id: item?.id,
        name: item?.name,
        is_active: item?.is_active,
        created_at: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm a'),
        description: item?.description,
        event_start_date_time: item?.event_start_date_time,
        event_end_time: item?.event_end_time,
        no_of_tickets: item?.no_of_tickets,
        price: item?.price,
        qr: item?.qr
      }));
      setRows(formattedData);
    }
  }, [events]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    showToastSuccess('QR Copied');
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setEventDetails(null);
  };

  const handleViewDetails = (eventId) => {
    getOrderEventDetails(eventId).then((response => {
      if (response && response?.length) {
        setEventDetails(response)
        setOpenModal(true);
      } else {
        setEventDetails(null)
        setOpenModal(false);
        showToastFailure('No customer data available for this event!');
      }
    }))
  }


  const handleRowEdit = async (updatedRow) => {
    const originalRow = events.find((row) => row.id === updatedRow.id);
  
    // Validate mandatory fields
    if (
      !updatedRow.name || updatedRow.name.trim() === '' ||
      !updatedRow.price || updatedRow.price === '' ||
      !updatedRow.description || updatedRow.description === '' ||
      !updatedRow.no_of_tickets || updatedRow.no_of_tickets === '' ||
      !updatedRow.event_start_date_time || updatedRow.event_start_date_time === '' ||
      !updatedRow.event_end_time || updatedRow.event_end_time === ''
    ) {
      showToastFailure('Title, Price, Description, No of Tickets, Start Date, and End Date are mandatory fields.');
      return originalRow;
    }
  
    const startDateTime = new Date(updatedRow.event_start_date_time);
    const endDateTime = new Date(updatedRow.event_end_time);
  
    // Check if start date is greater than end date
    if (endDateTime <= startDateTime) {
      showToastFailure('Invalid date: End date must be later than the start date.');
      return originalRow;
    }
  
    const hasChanged = Object.keys(updatedRow).some(
      (key) => updatedRow[key] !== originalRow[key]
    );
  
    if (!hasChanged) {
      return originalRow;
    }
  
    try {
      const response = await updateEventData(updatedRow);
  
      if (response.length) {
        const updatedRowData = {
          id: response[0]?.id,
          name: response[0]?.name,
          description: response[0]?.description,
          price: response[0]?.price,
          no_of_tickets: response[0]?.no_of_tickets,
          is_active: response[0]?.is_active,
          event_start_date_time: response[0]?.event_start_date_time,
          event_end_time: response[0]?.event_end_time,
          created_at: format(new Date(response[0]?.created_at), 'dd/MM/yyyy HH:mm a'),
        };
  
        return updatedRowData;
      } else {
        showToastFailure('Failed to update the row.');
        return originalRow;
      }
    } catch (error) {
      showToastFailure('An error occurred while updating the row.');
      return originalRow;
    }
  };
  

  const formatToGMT = (date) => {
    if (!date) return null;
    const isoDate = date.toISOString();
    return isoDate.replace('T', ' ').split('.')[0] + '+00';
  };

  const renderDatePickerCell = (params, fieldName) => {
    const isEditing = editingRowId === params.id;

    if (isEditing) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label={fieldName === 'event_start_date_time' ? 'Start Date & Time' : 'End Date & Time'}
            value={params.value ? new Date(params.value) : null}
            minDateTime={new Date(new Date().getTime() + 30 * 60 * 1000)}
            onChange={(newValue) => {
              // Update the picker UI with the new value, without saving yet
              params.api.setEditCellValue({ id: params.id, field: fieldName, value: newValue });
            }}

            // onAccept is triggered when the user clicks the OK button
            onAccept={(newValue) => {
              if (newValue) {
                const formattedDate = formatToGMT(newValue);
                const updatedRow = { ...params.row, [fieldName]: formattedDate };
                handleRowEdit(updatedRow); // Save the row only after the "OK" button is clicked
              }
            }}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
        </LocalizationProvider>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <span>{params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm') : ''}</span>
        <IconButton
          onClick={() => setEditingRowId(params.id)}
          size="small"
          style={{ marginLeft: 8 }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const columns = [
    { field: 'name', headerName: 'Title', flex: 1, editable: true },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={Array.isArray(params.value) ? params.value.join(', ') : params.value || ''} arrow>
        <span>{Array.isArray(params.value) ? params.value.join(', ') : params.value ?? ''}</span>
      </Tooltip>
      ),
    },
    { field: 'price', headerName: 'Price', flex: 0.4, type: 'number', editable: true },
    { field: 'no_of_tickets', headerName: 'Available Tickets', flex: 0.4, type: 'number', editable: true },
    // {
    //   field: 'qr', headerName: 'QR', flex: 0.2, type: 'number', editable: false,
    //   renderCell: (params) => (
    //     <Tooltip title="Copy QR">
    //       <IconButton
    //         onClick={() => handleCopy(params.value)}
    //         size="small"
    //       >
    //         <ContentCopyIcon />
    //       </IconButton>
    //     </Tooltip>
    //   ),

    // },
    {
      field: 'event_start_date_time',
      headerName: 'Event Date & Time',
      flex: 1,
      editable: true,
      renderCell: (params) => renderDatePickerCell(params, 'event_start_date_time'),
    },
    {
      field: 'event_end_time',
      headerName: 'Event End Time',
      flex: 1,
      editable: true,
      renderCell: (params) => renderDatePickerCell(params, 'event_end_time'),
    },
    { field: 'created_at', headerName: 'Date Created', flex: 1 },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const isActive = params.value;
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <SwitchWithLabels
              checked={isActive}
              onChange={(e) => {
                const newStatus = e.target.checked;
                const updatedRow = {
                  ...params.row,
                  is_active: newStatus
                };
                handleRowEdit(updatedRow);
              }}
            />
          </Box>
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Orders',
      flex: 0.5,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleViewDetails(params.row.id)} // Pass event ID to handle modal opening
          size="small"
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={38}
        pageSize={10}
        processRowUpdate={handleRowEdit}
        experimentalFeatures={{ newEditingApi: true }}
        onCellEditCommit={() => setEditingRowId(null)}
      />

      {/* Event Details Modal */}
      {eventDetails?.length && openModal &&
        <EventDetailsModal
          open={openModal}
          onClose={handleCloseModal}
          eventDetails={eventDetails}
        />
      }
    </div>
  );
};

export default EventDetailsTable;
