import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
 getEvents,
 updateEvent,
 useCreateEvent,
} from '../service/eventService';
import { useLoading } from './LoadingContext';

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
 const [selectedEventId, setSelectedEventId] = useState(null);
  const { createEvent } = useCreateEvent();
  const { showLoader, hideLoader } = useLoading();

  useEffect(() => {
    const getEventsList = async () => {
      const response =  await getEvents();
      setEvents(response)
    };
    getEventsList();
  }, []);

  const createNewEvent = async (newEvent) => {
  try {
    showLoader();
    const response = await createEvent(newEvent);
    if (response?.length) {
      // Update the passes state with the newly inserted pass
      const updatedEvent = response[0];
        setEvents(prevData => {
          const updatedData = [...prevData, updatedEvent];
          return updatedData;
        });
    
      return response;
    } 
  } catch (error) {
    return null;
  } finally {
    hideLoader();
  }
};

  const updateEventData = async (updateData) => {
    try {
      const response = await updateEvent(updateData);
     if (response?.length) {
        const updatedEvent = response[0];
        setEvents(prevRows => {
          const updatedRows = prevRows.map(row => 
            row?.id === updatedEvent.id ? updatedEvent : row
          );
          return updatedRows;
        });
        
        return response;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  

  return (
    <EventContext.Provider value={{
      events,
      setSelectedEventId,
      selectedEventId,
      createNewEvent,
      updateEventData
    }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => useContext(EventContext);