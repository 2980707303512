import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, Tooltip, Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useTheme } from '@emotion/react';
import React from 'react';

const EventDetailsModal = ({ open, onClose, eventDetails }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <>
              <div>Email: {params.row.userEmail}</div>
            </>
          }
          arrow
        >
          <span>{params.value}</span>
        </Tooltip>
      )
    },
    { field: 'phone', headerName: 'Mobile No', flex: 1 },
    { field: 'no_of_ticket_purchased', headerName: 'Tickets', flex: 0.6 },
    { field: 'amount', headerName: 'Amount', flex: 0.6 },
    { field: 'gstAmount', headerName: 'GST', flex: 0.6 },
    { field: 'paymentStatus', headerName: 'Payment Status', flex: 0.8 },
    { field: 'paymentId', headerName: 'Payment Id', flex: 1 },
    { field: 'createdAt', headerName: 'Created At', flex: 1 }
  ];

  // Map API response to the data grid format
  const formattedData = eventDetails?.map(item => ({
    id: item.id, // Use the id from the API response
    userName: `${item?.user?.first_name} ${item?.user?.last_name}`,
    userEmail: item.user.email,
    userDOB: item.user.dob, // if DOB is available
    phone: item.user.phone,
    amount: item.amount,
    no_of_ticket_purchased: item.no_of_ticket_purchased,
    gstAmount: item.gst_amount,
    paymentStatus: item.payment_status ? 'Success' : 'Failed',
    paymentId: item.payment_id || '',
    createdAt: format(new Date(item.created_at), 'dd/MM/yyyy HH:mm a'),
  }));

  // Calculate total amounts
  const totalAmount = formattedData?.reduce((acc, item) => acc + item.amount, 0) || 0;
  const totalGST = formattedData?.reduce((acc, item) => acc + item.gstAmount, 0) || 0;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" fullScreen={fullScreen}>
      <DialogTitle>Event Details</DialogTitle>
      <DialogContent>
        <div style={{ height: '70vh', width: '100%' }}>
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
            rows={formattedData}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            localeText={{ noRowsLabel: "No Data" }}
          />
        </div>
        {/* Footer with total amounts */}
        <Box display="flex" justifyContent="space-between" mt={2} p={2} >
          <Typography variant="h6">Total Amount: ₹{totalAmount?.toFixed(2)}</Typography>
          <Typography variant="h6">Total GST Collected: ₹{totalGST?.toFixed(2)}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDetailsModal;
