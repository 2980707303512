import { supabase } from "../client"
import { useUser } from "../contexts/UserContext";
import { getFormattedDate } from "../utils/formattedDateGMT";
import { showToastFailure, showToastSuccess } from "./toast";

export const getSessions = async (startDate, endDate) => {
    try {
        // Call the stored procedure
        const { data, error } = await supabase.rpc('get_session_details', {
          startdate: startDate,
          enddate: endDate
        });

        if (error) {
            throw error;
        }
        return data
    } catch (error) {
        showToastFailure('Error fetching dashboard data')
        throw error;
    }
};

export const useUpdateSessionsStatus = () => {
  const { user } = useUser();

  const updateSessionsStatus = async (sessionId, newStatus) => {
    try {
      const { data, error } = await supabase
        .from('sessions')
        .update({
          game_status: newStatus,
          updated_at: getFormattedDate(new Date()),
          updated_by: user ? user.id : null
        })
        .eq('id', sessionId)
        .select();

      if (error) {
        showToastFailure(error.message || "Unable to update, please refresh the screen");
        throw error;
      }

      showToastSuccess("Data Updated Successfully");
      return data;
    } catch (error) {
      showToastFailure(error.message || "Unable to update, try again later");
      return null;
    }
  };

  return { updateSessionsStatus };
};
export const createSession = async (order) => {
  try {
    console.log('order', order);
    // Step 1: Fetch the available tickets for the order's pass
    const { data: passData, error: passError } = await supabase
      .from('pass_details')
      .select('no_of_tickets, is_active') // Fetch is_active field too
      .eq('id', order?.pass_detail_id)
      .single();

    if (passError || !passData) {
      showToastFailure(passError?.message || "Unable to fetch pass details");
      return null;
    }

    const availableTickets = passData.no_of_tickets; // Fetch available_tickets
    const isPassActive = passData.is_active;

    // Step 2: Validate pass and ticket availability
    if (!isPassActive) {
      showToastFailure("Pass is not active");
      return null;
    }
    
    if (availableTickets < order.no_of_ticket_purchased) {
      showToastFailure("Tickets are not enough to place order, kindly update available tickets from master pass management");
      return null;
    }

    // Step 3: Create the session
    const { data: sessionsData, error: sessionError } = await supabase
      .from('sessions')
      .insert({
        order_id: order?.id, // Set the order_id to the actual order id
        game_status: 'ongoing',
        created_at: getFormattedDate(new Date())
      })
      .select();

    if (sessionError) {
      showToastFailure(sessionError.message || "Unable to create session, please refresh the screen");
      throw sessionError;
    }

    // Step 4: Confirm the order
    if (sessionsData?.length > 0) {
      const { data: orderData, error: orderError } = await supabase
        .from('orders')
        .update({
          order_confirmed: true
        })
        .eq('id', order?.id)
        .select();

      if (orderError || !orderData?.length) {
        showToastFailure(orderError?.message || "Unable to confirm order");
        return null;
      }

      // Step 5: Update available tickets
      await supabase
        .from('pass_details')
        .update({
          no_of_tickets: availableTickets - order.no_of_ticket_purchased // Decrease available tickets
        })
        .eq('id', order.pass_detail_id)
        .select();
    }

    showToastSuccess("Session Created Successfully");
    return {...order, order_confirmed: true};
  } catch (error) {
    showToastFailure(error.message || "Unable to update, try again later");
    return null;
  }
}

