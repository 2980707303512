import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, Button } from '@mui/material';
import { useDashboardData } from '../service/dashboard';
import { useUser } from '../contexts/UserContext';
import { formatIndianCurrency } from '../utils/IndianCurrencyConveter';
import { styled } from '@mui/material/styles';

// Styled Card
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: theme.shadows[4],
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const DashboardScreen = () => {
  const { getDashboardData, deleteExpiredEventImages } = useDashboardData();
  const [data, setData] = useState(null);
  const { user } = useUser();
  const isSuperAdmin = user?.role === 'super_admin';

  const fetchData = useCallback(async () => {
    const result = await getDashboardData();
    setData(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteExpiredImages = async () => {
    deleteExpiredEventImages();
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#f4f4f4' }}>
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteExpiredImages}
        sx={{ mb: 2, fontWeight: 'bold' }}
      >
        Delete Images for Expired Events
      </Button>
      <Typography variant="body2" color="red" sx={{ mb: 2, fontWeight: 'bold' }}>
        Note: This will permanently delete images for events that have expired. This needs to be done manually to save space in the database and is compulsory to be done every 7 days.
      </Typography>

      <Grid container spacing={3}>
        {/* Order Summary Card */}
        <Grid item xs={12} md={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                Order Summary For Passes
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body1">Today's Order Count: {formatIndianCurrency(data?.todays_order_count)}</Typography>
                  {isSuperAdmin && (
                    <>
                      <Typography variant="body1">Yesterday's Order Count: {data?.yesterdays_order_count}</Typography>
                      <Typography variant="body1">Last 7 Days Count: {data?.last_7_days_order_count}</Typography>
                      <Typography variant="body1">This Month's Order Count: {formatIndianCurrency(data?.this_month_order_count)}</Typography>
                      <Typography variant="body1">This Year's Order Count: {formatIndianCurrency(data?.this_year_order_count)}</Typography>
                      <Typography variant="body1">Total Orders: {formatIndianCurrency(data?.total_orders)}</Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Payment Summary Cards */}
        <Grid container spacing={2}>
          {/* Payment Summary for Passes */}
          <Grid item xs={12} md={4}>
            <StyledCard style={{marginTop: 10, marginLeft: 30}}>
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                  Payment Summary For Passes
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Today's Amount (with GST): {formatIndianCurrency(data?.todays_amount_with_gst?.toFixed(2)) || 0}
                    </Typography>
                    {isSuperAdmin && (
                      <>
                        <Typography variant="body1">
                          Yesterday's Amount (with GST): {formatIndianCurrency(data?.yesterdays_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          Last 7 Days Amount (with GST): {formatIndianCurrency(data?.last_7_days_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          This Month's Amount (with GST): {formatIndianCurrency(data?.this_month_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          This Year's Amount (with GST): {formatIndianCurrency(data?.this_year_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          Successful Payments: {formatIndianCurrency(data?.successful_payments)}
                        </Typography>
                        <Typography variant="body1">
                          Failed Payments: {formatIndianCurrency(data?.failed_payments)}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Payment Summary for Cash & Online Orders */}
          <Grid item xs={12} md={4}>
            <StyledCard style={{ marginTop: 10 }}>
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                  Payment Summary for Cash & Online Orders
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Today's Cash Orders: {formatIndianCurrency(data?.todays_cash_order_count) || 0}
                    </Typography>
                    <Typography variant="body1">
                      Today's Cash Amount (with GST): {formatIndianCurrency(data?.todays_cash_amount_with_gst?.toFixed(2)) || 0}
                    </Typography>
                    <Typography variant="body1">
                      Today's Online Orders: {formatIndianCurrency(data?.todays_online_order_count) || 0}
                    </Typography>
                    <Typography variant="body1">
                      Today's Online Amount (with GST): {formatIndianCurrency(data?.todays_online_amount_with_gst?.toFixed(2)) || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>

          {/* Payment Summary for Events */}
          <Grid item xs={12} md={4}>
            <StyledCard sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                  Payment Summary For Events
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Today's Amount (with GST): {formatIndianCurrency(data?.todays_event_amount_with_gst?.toFixed(2)) || 0}
                    </Typography>
                    {isSuperAdmin && (
                      <>
                        <Typography variant="body1">
                          Yesterday's Amount (with GST): {formatIndianCurrency(data?.yesterdays_event_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          Last 7 Days Amount (with GST): {formatIndianCurrency(data?.last_7_days_event_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          This Month's Amount (with GST): {formatIndianCurrency(data?.this_month_event_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                        <Typography variant="body1">
                          This Year's Amount (with GST): {formatIndianCurrency(data?.this_year_event_amount_with_gst?.toFixed(2)) || 0}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>


        {/* GST Card */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                GST Details
              </Typography>
              <Typography variant="body1">GST: {data?.total_gst?.toFixed(2)}%</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Pass Details Card */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                Pass Details
              </Typography>
              <Typography variant="body1">Total Pass Details: {data?.total_pass_details}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Users Card */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ color: '#756394', fontWeight: 'bold' }}>
                Users Summary
              </Typography>
              <Typography variant="body1">Total Users: {data?.total_users}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>

      </Grid>
    </Box>
  );
};

export default DashboardScreen;
