import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, AccountCircle as AccountCircleIcon, Person as PersonIcon, Photo as PhotoIcon, LocalActivity as LocalActivityIcon, Assignment as AssignmentIcon, AttachMoney as AttachMoneyIcon, CalendarToday as CalendarTodayIcon, EmojiEvents as EmojiEventsIcon, QrCode as QrCodeIcon, Home as HomeIcon } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip';
import Customers from '../pages/Customers';
import Pass from '../pages/Pass';
import { useUser } from '../contexts/UserContext';
import PassDetails from '../pages/PassDetails';
import Orders from '../pages/Orders';
import DashboardScreen from '../pages/Dashboard';
import Sessions from '../pages/Sessions';
import ImageGallery from '../pages/ImageGallary';
import Events from '../pages/Events';
import QR from '../pages/Qr';
const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1, 0.5),
  height: '20px',
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#756394',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Sidenav = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const { user, clearUser } = useUser();
  const isSuperAdmin = user?.role === 'super_admin';

  useEffect(() => {
    const { pathname } = location;
    if (user) {
      if (pathname.startsWith('/app/dashboard') || pathname.startsWith('/') ||
        pathname.startsWith('/app')) {
        setSelectedMenuItem('dashboard');
      }
      if (pathname.startsWith('/app/sessions')) {
        setSelectedMenuItem('sessions');
      }
      if (pathname.startsWith('/app/all-customers')) {
        setSelectedMenuItem('all-customers');
      } if (pathname.startsWith('/app/pass')) {
        setSelectedMenuItem('pass');
      }
      if (pathname.startsWith('/app/passDetails')) {
        setSelectedMenuItem('passDetails');
      }
      if (pathname.startsWith('/app/orders')) {
        setSelectedMenuItem('orders');
      }
      if (pathname.startsWith('/app/banners')) {
        setSelectedMenuItem('banners');
      }
      if (pathname.startsWith('/app/qr-management')) {
        setSelectedMenuItem('qr');
      }
      if (pathname.startsWith('/app/events')) {
        setSelectedMenuItem('events');
      }
    }

  }, [location, user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    clearUser();
    navigate('/login')
  };

  const handleViewAllCustomersClick = () => {
    setSelectedMenuItem('all-customers');
    navigate('/app/all-customers');
  }

  const handleBannerManagement = () => {
    setSelectedMenuItem('banners');
    navigate('/app/banners');
  }

  const handleEventManagement = () => {
    setSelectedMenuItem('events');
    navigate('/app/events');
  }

  const handleViewSeasonClick = () => {
    setSelectedMenuItem('sessions');
    navigate('/app/sessions');
  }

  const handleViewQRdClick = () => {
    setSelectedMenuItem('qr');
    navigate('/app/qr-management');
  }

  const handleViewPassClick = () => {
    setSelectedMenuItem('pass');
    navigate('/app/pass');
  }

  const handlePassDetails = () => {
    setSelectedMenuItem('passDetails');
    navigate('/app/passDetails');
  }

  const handleOrders = () => {
    setSelectedMenuItem('orders');
    navigate('/app/orders');
  }

  const handleViewDashboardClick = () => {
    setSelectedMenuItem('dashboard');
    navigate('/app/dashboard');
  }

  const profileMenuItems = [
    { label: 'Logout', onClick: handleLogout },
  ];


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={process.env.PUBLIC_URL + 'app_logo_white.webp'}
              style={{ maxHeight: '3em', width: 'auto' }}
              alt=''
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <span>{user && (user.fullName)}</span>
            <IconButton
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >
              {profileMenuItems.map((item) => (
                <MenuItem key={item.label} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <List>
          {/* Dashboard Item */}
          <ListItem
            key="dashboard"
            disablePadding
            onClick={handleViewDashboardClick}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'dashboard' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="Dashboard">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

        <List>
          {/* QR Screen */}
          <ListItem
            key="qr"
            disablePadding
            onClick={handleViewQRdClick}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'qr' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="QR Management">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <QrCodeIcon />
                </ListItemIcon>
                <ListItemText primary="QR Management" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

        <List>
          <ListItem
            key="sessions"
            disablePadding
            onClick={handleViewSeasonClick}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'sessions' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="Manage Customer Sessions (complete sessions, view customer feedback, view sessions details)">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText primary="Customer Sessions" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

        <List>
          <ListItem
            key="orders"
            disablePadding
            onClick={handleOrders}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'orders' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="View Customer Orders and details of orders">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Customer Orders" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>


        {isSuperAdmin && <>   <List>
          <ListItem
            key="all-customers"
            disablePadding
            onClick={handleViewAllCustomersClick}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'all-customers' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="View your customer details">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Customers" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

          <List>
            <ListItem
              key="pass"
              disablePadding
              onClick={handleViewPassClick}
              sx={{
                display: 'block',
                backgroundColor: selectedMenuItem === 'pass' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <Tooltip title="Manage your master pass">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <LocalActivityIcon />
                  </ListItemIcon>
                  <ListItemText primary="Master Pass Management" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>

          <List>
            <ListItem
              key="passDetails"
              disablePadding
              onClick={handlePassDetails}
              sx={{
                display: 'block',
                backgroundColor: selectedMenuItem === 'passDetails' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <Tooltip title="Manage your game passes, pricing, tickets and much more ">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sub Pass Management" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </>
        }
        <List>
          <ListItem
            key="banners"
            disablePadding
            onClick={handleBannerManagement}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'banners' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="Mobile APP Banners for general information (No need to upload event's image, you can upload event images from events screen)">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PhotoIcon />
                </ListItemIcon>
                <ListItemText primary="Mobile Banners" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

   <List>
          <ListItem
            key="events"
            disablePadding
            onClick={handleEventManagement}
            sx={{
              display: 'block',
              backgroundColor: selectedMenuItem === 'events' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Tooltip title="Manage your upcoming events">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary="Event Management" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
      </List> 


      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {selectedMenuItem === 'dashboard' && <DashboardScreen />}
        {selectedMenuItem === 'sessions' && <Sessions />}
        {selectedMenuItem === 'orders' && <Orders />}
        {selectedMenuItem === 'banners' && <ImageGallery />}
        {(selectedMenuItem === 'all-customers' && isSuperAdmin) && <Customers />}
        {(selectedMenuItem === 'pass' && isSuperAdmin) && <Pass />}
        {(selectedMenuItem === 'passDetails' && isSuperAdmin) && <PassDetails />}
        {(selectedMenuItem === 'qr') && <QR />}
        {(selectedMenuItem === 'events' && isSuperAdmin) && <Events />}
      </Box>
    </Box>
  );
}
export default Sidenav;