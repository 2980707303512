import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useOrdersData } from '../service/orders';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { showToastFailure } from '../service/toast';
import { format, startOfDay, endOfDay } from 'date-fns';
import { Modal, Box, Typography, IconButton, Divider, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import ConfirmationModal from '../components/ConfirmationModal';
import { createSession } from '../service/sessions';

const OrdersTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const todayDate = new Date()
  const [startDate, setStartDate] = useState(startOfDay(todayDate));
  const [endDate, setEndDate] = useState(endOfDay(todayDate));
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { getOrders } = useOrdersData()

  const fetchData = async () => {

    // Format the dates to ISO 8601 strings
    const startOfTodayISOString = new Date(startDate).toISOString();
    const endOfTodayISOString = new Date(endDate).toISOString();

    const data = await getOrders(startOfTodayISOString, endOfTodayISOString);

    const formattedData = data?.map(item => ({
      id: item?.order_id,
      userName: `${item?.user_details.first_name} ${item?.user_details.last_name}`,
      userEmail: item?.user_details.email,
      phone: item.user_details.phone,
      passTitle: item?.pass_details.title,
      amount: item?.amount,
      no_of_ticket_purchased: item?.no_of_ticket_purchased,
      gstAmount: item?.gst_amount,
      paymentStatus: item?.payment_status ? 'Success' : 'Failed',
      paymentMode: item?.payment_mode,
      orderConfirmed: item?.order_confirmed,
      paymentId: item?.payment_id || '',
      createdAt: format(new Date(item.order_created_at), 'dd/MM/yyyy HH:mm a'),
      order_metadata: item.order_metadata ?? [],
      pass_detail_id: item?.pass_detail_id
    }));
    setRows(formattedData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setModalOpen(false);
  };

  const handleCashOrderConfirm = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  }

  const handleConfirm = async () => {
    try {
      // Call the function to confirm the order and create the session
      const result = await createSession(selectedRow);
  
      if (result) {
        // Update the local state to reflect the confirmed order
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedRow.id ? { ...row, orderConfirmed: true } : row
          )
        );
      }
    }finally {
      setModalOpen(false); // Close the modal after the process
    }
  };
  

  const columns = [
    { field: 'userName', headerName: 'User Name', flex: 0.6 },
    { field: 'phone', headerName: 'Mobile No', flex: 0.3 },
    { field: 'passTitle', headerName: 'Pass Title', flex: 0.4 },
    { field: 'no_of_ticket_purchased', headerName: 'Tickets', flex: 0.3 },
    { field: 'amount', headerName: 'Amount', flex: 0.3 },
    { field: 'gstAmount', headerName: 'GST', flex: 0.3 },
    {
      field: 'paymentMode',
      headerName: 'Mode',
      flex: 0.3,
    },
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 0.3,
    },
    {
      field: 'orderConfirmed',
      headerName: 'Confirm Order',
      flex: 0.4,
      renderCell: (params) => (
        params.value ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
              width: '100%',
              height: '100%',
            }}
          >
            <Button startIcon={<CheckCircleIcon style={{ color: green[500] }} />}>
            </Button>

          </Box>
        ) : (
          <Button onClick={() => handleCashOrderConfirm(params.row)}>
            Confirm
          </Button>
        )
      ),
    },
    {
      field: 'paymentId',
      headerName: 'Payment Id',
      flex: 0.5,
    },

    { field: 'createdAt', headerName: 'Created At', flex: 0.5 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <IconButton onClick={() => handleOpen(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
      flex: 0.6
    },
  ];

  const handleStartDateChange = (date) => {
    if (date && endDate && date > endDate) {
      showToastFailure('Start date cannot be after end date.')
      setStartDate(null);
    } else {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    if (date && startDate && date < startDate) {
      showToastFailure('End date cannot be before start date.')
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  return (
    <>
      <div style={{ padding: '10px 0' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={styles.datepickerWrapper}>
            <DatePicker
              showIcon
              closeOnScroll={true}
              isClearable
              toggleCalendarOnIconClick
              selected={startDate}
              value={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd-MM-yyyy"
              placeholderText="Start Date"
              style={{ zIndex: 1000 }}
            />
          </div>
          <div style={styles.datepickerWrapper}>
            <DatePicker
              showIcon
              closeOnScroll={true}
              isClearable
              toggleCalendarOnIconClick
              selected={endDate}
              value={endDate}
              onChange={handleEndDateChange}
              maxDate={new Date()}
              dateFormat="dd-MM-yyyy"
              placeholderText="End Date"
              style={{ zIndex: 1000 }}
            />
          </div>
          <Button
            style={{ backgroundColor: '#756394', color: '#fff' }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (startDate && endDate) {
                fetchData()
              }
            }}
          >
            Filter
          </Button>
        </div>

        <div style={{ height: 600, marginTop: 10, width: '100%' }}>
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
            rows={rows}
            columns={columns}
            pageSize={20}
            loading={loading}
            localeText={{ noRowsLabel: "No Orders" }}
          />
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxHeight: 800,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflow: 'auto'
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography id="modal-title" variant="h6" component="h2">
                Order Details
              </Typography>
              <IconButton onClick={handleClose} color="primary">
                <CloseIcon />
              </IconButton>
            </Box>

            {selectedRow && (
              <Box>

                <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Tickets Sold:</strong> {selectedRow.no_of_ticket_purchased}</Typography>

                {selectedRow?.order_metadata?.length > 0 && <Typography sx={{ mr: 2 }} variant="subtitle1">
                  <strong>Extra Info </strong>

                  {selectedRow?.order_metadata.map((metaData => {
                    return (
                      <>
                        <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Full Name:</strong> {metaData?.fullName}</Typography>
                        <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Phone:</strong> {metaData?.phone}</Typography>
                        <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>Email:</strong> {metaData.email}</Typography>
                        <Typography sx={{ mb: 1 }} variant="subtitle1"><strong>DOB:</strong> {format(new Date(metaData.dob), 'dd/MM/yyyy')} </Typography>
                        <Divider sx={{ my: 2 }} />
                      </>
                    )
                  }))}

                </Typography>}
              </Box>
            )}
          </Box>
        </Modal>
      </div>

      <ConfirmationModal
        open={modalOpen}
        title="Confirm Order"
        description={`Are you sure you want to approve the order for ${selectedRow?.userName} (${selectedRow?.phone}) with a total amount of ${selectedRow ? selectedRow.amount + selectedRow.gstAmount : 0
          }? Once the order is confirmed, it cannot be changed. Please manually check that you have already accepted the cash.`}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
};

export default OrdersTable;

const styles = {
  datepickerWrapper: {
    display: 'inline-block',
    border: '2px solid #756394', /* Border color */
    borderRadius: '8px', /* Rounded corners */
    padding: '8px', /* Padding around the DatePicker */
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', /* Shadow for depth */
    backgroundColor: '#fff', /* Background color */
    position: 'relative', /* Ensure z-index works */
  },
  reactDatepickerTriangle: {
    display: 'none', /* Hide the triangle if you don't want it */
  }
};
